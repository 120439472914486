:root {
    --input-bg-color: #f4f6f850;
    --input-text-color: black;
    --input-border-color: #f4f6f8;
}

body {
    margin: 0 !important;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

h4 {
    text-align: left;
    margin-left: 40px;
}

hr {
    /* border-top: 2px dotted #3f51b5; */
}

button {
    margin-right: 10px;
    background: #95969c;
    color: white;
    border: none;
    padding: 5px 10px;
    /*width: 100px;*/
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
}

/* For pdf */

.react-pdf__Page {
    margin-top: 10px;
}

.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

/* For all pages */

.all-page-container {
    height: 100%;
    max-height: 500px;
    overflow: auto;
}

/* sidebar */

.sidebar.os-host.os-theme-light.os-host-resize-disabled.os-host-scrollbar-vertical-hidden.os-host-transition.os-host-scrollbar-horizontal-hidden {
    width: inherit;
}

.brand-link {
    /*height: 80px;*/
    display: flex;
    align-items: center;
}

.main-sidebar {
    background: #0747a6;
    font-size: 14px;
    color: #ffffff;
}

.main-sidebar p {
    color: inherit;
}

.main-sidebar a.nav-link {
    color: white !important;
}

.main-sidebar a.nav-link.active {
    /*background: rgb(5, 54, 127) !important;*/
    background: #ffffff !important;
    color: #000000 !important;
    font-weight: 600;
}

.main-sidebar .menu-open a.nav-link:hover {
    /*background: rgb(5, 54, 127);
    color: white;*/
    background: #ffffff !important;
    color: #000000 !important;
}

.nav-link .text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-link .text {
    display: flex;
    align-items: center;
}

.user-panel {
    align-items: center;
}

.user-panel a {
    color: white;
}

.brand-text {
    margin-left: 10px;
    font-size: 14px;
}

/* sidebar */

/* header */

.content-header,
.content-header .col-sm-6 {
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
}

.content-header h1 {
    font-size: 2.3rem;
    font-weight: 800;
    color: white;
}

.main-header {
    margin-bottom: 0;
}

.breadmr {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    margin-top: 0px;
}

.breadcrumb-item a {
    color: white;
    font-weight: 600;
}

.breadcrumb-item.active {
    color: rgb(7, 71, 166);
}

.action-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* header */

/* action */

.universal-length {
    font-size: 14px !important;
    height: 32px !important;
}

.recherche {
    width: 20em !important;
    border: 0;
    border-radius: 0px 5px 5px 0px !important;
}

.icon-r {
    width: 30px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0px 0px 5px;
}

.mbtn:hover > i {
    font-size: 18px;
}

.mbtn:hover {
    color: rgb(7, 71, 166);
    background: white;
}

.mbtn {
    font-size: 14px !important;
    height: 40px !important;
    background: rgb(7, 71, 166);
    border: 0;
    -webkit-box-shadow: 0px 10px 15px -2px rgba(8, 71, 166, 0.67);
    -moz-box-shadow: 0px 10px 15px -2px rgba(8, 71, 166, 0.67);
    box-shadow: 0px 10px 15px -2px rgba(8, 71, 166, 0.67);
    /*border-radius: 10px;*/
}

.fa-plus-circle {
    /* font-size : 40px;
  position:absolute; */
    margin-right: 5px;
}

.button-action {
}

/* action */

/* content-wrapper */

.content-wrapper {
    padding: 1em 3em 1em 3em;
    background: #f4f5f7;
}

/* content-wrapper */

/* BOX */

.no-padding-m {
    padding: 0;
}

.box-item {
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    padding: 2%;
    background-color: white;
    width: 25%;
    max-width: 25%;
    flex-grow: 1;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    /* display: flex; */
}

.tabledashboard {
    background-color: white;
}

/* @media (min-width : 769px){
  .box-item{
    max-width : 300px;
  }
} */

.box-item.detail {
    margin: 0;
    width: 100%;
    max-width: none;
}

.image-olona img {
    width: 100%;
}

.image-olona.detail {
    margin: 0 auto;
    height: 160px;
    width: 160px;
}

.image-olona {
    max-height: 80px;
    max-width: 80px;
    overflow: hidden;
    border-radius: 50%;
    background: white;
    border: 1px solid #efefef;
    display: flex;
    align-items: center;
}

.box-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    /* justify-content: center; */
}

.box-item .r-1 .general-infos {
    padding-left: 0;
}

.box-item .r-1 .general-infos .name {
    font-size: 1.5em;
}

.box-item .r-2 h4 {
    font-weight: 800;
    color: grey;
    margin: 0;
    margin-bottom: 10px;
}

.box-item .r-2 h5 {
    font-weight: 800;
    color: grey;
}

.ok {
    color: #07a664;
}

.oknt {
    color: #ee6363;
}

.box-item .r-2 p {
    font-size: 1.5em;
}

.box-item .r-2 {
    margin-top: 3em;
}

.box-item .r-3 .col-6.left {
    padding-left: 18px;
}

.box-item .r-3 .col-6.right {
    padding-right: 18px;
}

.box-item .r-3 .col-6 p i {
    margin-right: 10px;
}

.box-item .r-3 .col-6 h5 {
    margin-bottom: 10px;
}

.box-item .r-3 .col-6 p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 800;
}

.box-item .r-3 .col-6 {
    padding-top: 10px;
    border: 1px solid #f5f5f5;
}

.box-item .r-3 {
    background: #fbfbfb;
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.box-item .general-infos p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
}

.box-item .general-infos .poste {
    font-size: 1.2em;
    color: grey;
}

.box-item .general-infos .dispo {
    background-color: #07a664;
    color: white;
    text-transform: uppercase;
    padding: 2px 5px;
    border-radius: 20px;
}

.igrey {
    color: #47676e;
}

.sep {
    height: 7px;
}

.text-black {
    color: black;
}

.stars {
    color: #e9ecef;
    font-size: 1.5em;
}

.stars.active-1 > i:first-child,
.stars.active-2 > i:nth-child(-n + 2),
.stars.active-3 > i:nth-child(-n + 3),
.stars.active-4 > i:nth-child(-n + 4),
.stars.active-5 > i:nth-child(-n + 5),
.stars.active-6 > i:nth-child(-n + 6),
.stars.active-7 > i:nth-child(-n + 7),
.stars.active-8 > i:nth-child(-n + 8),
.stars.active-9 > i:nth-child(-n + 9),
.stars.active-10 > i {
    color: #07a664;
}

.btn-favori:hover {
    color: #07a664;
    border: 1px solid #07a664;
}

.btn-favori.delete:hover {
    color: #ee6363;
    border: 1px solid #ee6363;
    background: white;
}

.btn-favori.delete {
    background: #ee6363;
}

.btn-favori {
    background: #07a664;
    box-shadow: none;
}

.progress-bar {
    background: #0747a6ea;
}

.box-info i {
    font-size: 3em;
    color: #bdbbbb;
}

.box-info {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* BOX */

/* MODAL */

.my-modal .close {
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: white;
    opacity: 1;
    border-radius: 50%;
    width: 40px !important;
    height: 40px;
    z-index: 9999999;
}

.my-modal .modal-content {
    border-radius: 5px;
    overflow: hidden;
}

.my-modal .modal-dialog {
    left: 0;
    margin-left: 40px;
    max-width: 100%;
    margin-right: 40px;
}

.my-modal .modal-body {
    padding: 0;
}

.my-modal .modal-header {
    border: 0;
}

.my-modal .close {
    width: auto;
    box-shadow: none;
    font-size: 2em;
}

@media (max-width: 576px) {
    /* .my-modal .modal-dialog{
    margin:6%;
  } */
}

/* MODAL */

/* REGISTER BOX */

.box-register .title {
    margin-bottom: 4em;
}

.text-highlight {
    font-size: 1.4em;
}

.box-register label {
    font-size: 1.7em;
}

.box-register h2 {
    font-size: 2.5em;
    font-weight: 700;
}

.box-register .row {
    margin-top: 8px;
    margin-bottom: 8px;
}

.box-register input:focus {
    border: 1px solid var(--input-border-color);
    color: var(--input-text-color);
    letter-spacing: 0.3mm;
    font-size: 1.4em;
}
.box-register input,
.box-register select {
    background: var(--input-bg-color);
    border: 1px solid var(--input-border-color);
    /*border: 0;*/
    height: 35px;
    font-size: 1.4em;
    font-weight: 600;
    color: var(--input-text-color);
    letter-spacing: 0.3mm;
}
.custom-text-blue {
    /* color : #05367f; */
}

input[type='file'] {
    height: auto;
}

input[type='file']::before {
    content: 'choisir un fichier';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

input[type='file']::-webkit-file-upload-button {
    visibility: hidden;
}

.btn-register {
    font-size: 2em;
    border-radius: 5px;
    background-color: #07a664;
}

.title-container {
    display: flex;
    justify-content: space-between;
}

.left-image-register .text p:first-child {
    font-size: 3em;
    font-weight: 800;
}

.left-image-register .text p:nth-child(2) {
    font-size: 2em;
}

.left-image-register .text p:nth-child(3) {
    font-size: 2.2em;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px;
    background: white;
    color: #010506;
    padding-left: 20px;
    padding-right: 20px;
}

.left-image-register .text {
    padding-top: 3rem;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.left-image-register {
    position: relative;
    color: white;
    background-image: url('../assets/images/bg-register.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
}

.left-image-register:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.submitted h2 {
    margin-top: 3em;
    margin-bottom: 3em;
}

.submitted {
    padding: 3em;
}

/* END REGISTER BOX */

/* FOOTER */

.main-footer {
    font-size: 12px;
}

/* END FOOTER */

.react-pdf__Page__textContent {
    border: 0;
    box-shadow: none;
}

.react-pdf__Page {
    margin-top: 0;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
}

.affect {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    font-size: 1.7em;
    font-weight: 600;
    flex-wrap: wrap;
}

.affect div {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.affect div:nth-child(2) {
    width: 300px;
    flex-grow: 1;
}

.affect select {
    background: white;
    color: black;
    padding-left: 5px;
    border-radius: 5px;
    /*border: 0; */
    border: 1px solid var(--input-border-color);
    height: 35px;
    font-size: 1.3em;
    font-weight: 600;
    width: 100%;
}

.affect button,
.comment-btn {
    /*background: #07A664;*/
    color: white;
    height: 35px;
    font-size: 1.1em;
    font-weight: 600;
    /*border-radius: 5px;*/
}

/* USERLIST */

.userList .table {
    background: white;
    font-size: 1.2em;
}

.userList .table a {
    color: #007bff;
}

.userList .table i {
    /*color: #07A664;*/
}

/* END USERLIST */

/* SPONTANE */

.spontane .table {
    background: white;
    font-size: 1.2em;
}

.disponibilite {
    background-color: rgb(7, 71, 166);
    color: white;
    text-transform: uppercase;
    padding: 2px 5px;
    font-weight: 700;
}

.decision button {
    color: white;
    text-transform: uppercase;
    padding: 5px 5px;
    border-radius: 2px;
    font-weight: 600;
}

.decision.accept button {
    background-color: #07a664;
}

.decision.deny button {
    background-color: #ee6363;
}

.spontane .table td,
.spontane .table th {
    vertical-align: middle;
}

/*END SPONTANE */

/* REGISTER */

.user-register .box {
    margin: 0 auto;
    width: 70%;
    background: white;
    padding: 20px 30px;
    border-radius: 5px;
}

.user-register input,
.user-register select {
    background: var(--input-bg-color);
    border: 0 !important;
    height: 35px;
    font-size: 1.4em !important;
    font-weight: 600 !important;
    color: var(--input-text-color) !important;
    letter-spacing: 0.3mm !important;
}

.user-register input:focus {
    border: 1px solid var(--input-border-color) !important;
    color: var(--input-text-color) !important;
    letter-spacing: 0.2mm !important;
    font-size: 1.4em !important;
}

.user-register .form-group {
    margin-bottom: 2rem !important;
}

.user-register button {
    font-size: 1.3em;
    font-weight: 800;
}

.content-wrapper.bg {
    content: '';
    background-image: url('../assets/images/pattern1.png');
    /* opacity: 0.5; */
}

/* END REGISTER */

/* TEST EVALUATION */

.test-evaluation section {
    background: white;
}

.test-evaluation-wrapper .head {
    background: rgb(7, 71, 166);
    background: linear-gradient(107deg, rgba(7, 71, 166, 1) 0%, rgba(39, 54, 158, 1) 100%);
}
.test-evaluation-wrapper .o {
    font-size: 25px;
}
.test-evaluation-wrapper .olona-logo-text {
    font-weight: 800;
    color: white;
}
.test-evaluation-wrapper .mjumbotron {
    color: white;
}
.mjumbotron .text {
    font-size: 3rem;
    min-height: 300px;
    display: flex;
    align-items: center;
}
.mjumbotron .image {
    align-items: center;
    justify-content: center;
    display: flex;
}
.mjumbotron .image img {
    max-width: 400px;
}
.filler {
    height: 100px;
}
.test-evaluation-wrapper {
    background: #f4f6f8;
}
.test-evaluation .content {
    padding-top: 4em;
    padding-bottom: 4em;
    margin-bottom: 5em;
    position: relative;
    /*top : -50px;*/
    border-radius: 5px;
}
.test-logo {
    filter: invert();
    height: 50px;
}

.message-container {
    font-weight: 600;
    font-size: 1.3em;
    background: white;
    height: 70vh;
    border: 1px solid #efefef;
    border-bottom: 0;
    position: relative;
    padding-bottom: 5px;
    overflow-y: scroll;
}
.messenger .send {
    height: 70px;
    background: white;
}
.message-container .line {
    display: flex;
}
.message-container .line .date {
    color: #a0a2a6;
    text-align: right;
}
.message-container .line.me {
    justify-content: flex-end;
}

.message-container .body {
    padding: 10px;
    max-width: 50%;
}
.message-container .me .message {
    display: flex;
    justify-content: flex-end;
}
.message-container .sender {
    margin-left: 4px;
    margin-bottom: 2px;
}
.message-container .me .sender {
    margin-right: 4px;
    text-align: right;
}
.message-container .me .message {
    /* background-color: rgb(35, 35, 235);
    color : white;
     */
}
.message-container .me .content {
    background-color: rgb(35, 35, 235);
    color: white;
    border-radius: 20px;
}
.message-container .content {
    padding: 10px;
    width: 100%;
    background-color: rgb(228, 230, 236);
    border-radius: 20px;
    width: fit-content;
}
.messenger .send {
    display: flex;
    align-items: center;
}
.messenger .commentaire-detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
}
.send textarea:focus {
    background-color: #f0f2f5;
}
.send textarea {
    font-weight: 700;
    padding-top: 15px;
    padding-left: 15px;
    font-size: 1.3em;
    width: 80%;
    background: #f0f2f5;
    border: 0;
    border-radius: 20px;
}
.send button {
    margin: 0;
}

/* TEST EVALUATION */

/* PAGINATION */
.pagination {
    justify-content: center;
}
.page-item {
    font-size: 1.2em;
}
.page-item.active .page-link {
    background: #0747a6 !important;
    border: 1px solid #0747a6 !important;
    color: white;
    font-weight: 600;
}
.page-item .page-link {
    color: #0747a6;
}

/* PAGINATION */

/* WYSIGWYG */
.toolbarClassName,
.editorClassName {
    background: white;
    /*border : 1px solid #efefef;*/
    padding: 10px;
    border: 1px solid var(--input-border-color);
    /* border: 0; */
    /*height: 35px;*/
    font-size: 1.4em;
    color: var(--input-text-color);
}

/* WYSIGWYG */

.btn-save-contact {
    background-color: #0e66c3;
    display: flex;
    width: auto;
    margin: 0 auto;
}

.btn-save-information {
    display: flex;
    position: inherit;
    width: 100%;
    display: inline;
    margin-right: -5px;
    font-size: 2em;
    border-radius: 5px;
    background-color: #07a664;
}
/* MAILBOX */

.mailbox-container {
    /*width : 80%; */
    margin: 0 auto;
    font-size: 1.2em;
    font-weight: 600;
}
.table-inbox {
    border: 1px solid #d3d3d3;
    margin-bottom: 0;
    background: white;
}
.table-inbox tr td {
    padding: 12px !important;
}
.table-inbox tr td:hover {
    cursor: pointer;
}
.table-inbox tr td .fa-star.inbox-started,
.table-inbox tr td .fa-star:hover {
    color: #f78a09;
}
.table-inbox tr td .fa-star {
    color: #d5d5d5;
}
.table-inbox tr.unread td {
    background: none repeat scroll 0 0 #f7f7f7;
    font-weight: 600;
}

.my-modal.mail .modal-dialog {
    left: 0;
    max-width: 50%;
}
.my-modal.mail .modal-content {
    border-radius: 0;
}
.mail-details {
}
.my-modal.mail .title {
    padding: 10px;
    padding-top: 13px;
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
}
.my-modal.mail .date {
    padding-bottom: 10px;
    padding-right: 10px;
    background: #f5f5f5;
    text-align: right;
}
.my-modal.mail .delete {
    color: red;
}
.my-modal.mail .title .sender {
    font-size: 2em;
    text-transform: capitalize;
}
.my-modal.mail .content {
    padding: 10px;
    padding-top: 25px;
}
/* MAILBOX */

/*table curve*/
.table-curved {
    border-collapse: collapse;
}
.table-curved th {
    position: relative;
    background-color: black;
    color: white;
    padding: 3px 10px;
    /*border-bottom: 10px solid transparent;*/
    border-top: 3px solid transparent;
}
.table-curved td {
    position: relative;
    background-color: white;
    padding: 6px 10px;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
}

.table-curved tr {
    border-radius: 10px;
}

.table-curved tr:hover td {
    background-color: #c5c5c5;
}

.test-evaluation {
    position: relative;
    /*color: white;*/
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
}
@media (max-width: 425px) {
    .box-item {
        padding: 5%;
    }
    .box-item .r-2 p {
        font-size: 1em;
    }
    .box-item .r-2 {
        margin-top: 1em;
    }
    .box-item .r-2 h5 {
        font-size: 0.8em;
    }
    .box-item .r-1 .general-infos .name {
        font-size: 1em;
    }
}
.content-wrapper > .content {
    padding: 0rem;
}

.btnFacebook {
    width: 165px;
    height: 35px;
    border-radius: 4px;
    background: #3b5998;
    color: white;
    border: 0px transparent;
    text-align: center;
    margin: 5px;
    display: inline-block;
}

.shadow-textarea textarea.form-control::placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control {
    padding-left: 0.8rem;
}
