@media all and (min-width: 200px) {
    .Login {
        padding: 0px 0;
    }

    .formLogin {
        margin: 0 auto;
        /*max-width: 320px;*/
    }
}

/* LOGIN */
html,
body,
#root,
.login-style {
    height: 100% !important;
}
.login-style {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/bg-login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-style > div {
    width: 30%;
    border-radius: 20px;
    background: white;
    border: 1px solid grey;
}
@media (max-width: 540px) {
    .login-style > div {
        width: 100%;
    }
}
@media (min-width: 541px) and (max-width: 830px) {
    .login-style > div {
        width: 60%;
    }
}
@media (min-width: 831px) and (max-width: 1024px) {
    .login-style > div {
        width: 50%;
    }
}
.Login {
    padding: 30px 30px 30px 30px;
}
.Login .image img {
    width: 100%;
}
.Login .image {
    margin-bottom: 20px;
}
.Login input {
    background: #f4f6f8;
    border: 0;
    height: 35px;
    font-size: 1.3em;
    font-weight: 600;
}
.Login input:focus {
    border: 1px solid #f4f6f8;
}
.Login button {
    margin-top: 40px;
    background-color: rgb(7, 71, 166);
    border: 0;
    font-weight: 700;
}

/* END LOGIN */
